import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { PolicyQueryParams } from '@/features/Policies/policyTypes';

export function useNavigateToOverview() {
  const navigate = useNavigate();
  const policyId = useContext(PolicyIdContext);
  const policyOverviewPath = `/search/policies?${PolicyQueryParams.Id}=${policyId}&${PolicyQueryParams.Section}=overview`;

  return () => navigate(policyOverviewPath);
}
