import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import { useContext, useEffect } from 'react';
import { StyledButtonsFooter } from '@/components/ui/styles/StyledButtonsFooter';
import { useGenerateContactUsEmailMutation } from '@/hooks/mutations/useGenerateContactUsEmailMutation';
import { PolicyIdContext } from '@/contexts/PolicyIdContext';
import { useMotorPolicyQuery } from '@/hooks/queries/useMotorPolicyQuery';
import { useSnackbarAlert } from '@/hooks/useSnackbarAlert';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';
import { useSetAlertError } from '@/hooks/useSetAlertError';
import { useNavigateToOverview } from '@/hooks/useNavigateToOverview';

export function ContactUsEmail() {
  const { setAlert } = useSnackbarAlert();
  const policyId = useContext(PolicyIdContext);
  const { data: policy } = useMotorPolicyQuery(policyId);
  const {
    mutate: generateContactUsEmail,
    isPending: isGenerateContactUsEmailMutationPending,
    isSuccess: isGenerateContactUsEmailMutationSuccess,
    isError: isGenerateContactUsEmailMutationError,
  } = useGenerateContactUsEmailMutation();
  const navigateToOverview = useNavigateToOverview();

  const firstName = policy?.policyholder.firstName ?? '';
  const policyHolderEmail = policy?.policyholder.email ?? '';
  const customerId = policy?.policyholder.customerId ?? '';

  const handleGenerateContactUsEmail = () =>
    generateContactUsEmail({
      policyId,
      policyHolderEmail,
      firstName,
      customerId,
    });

  useSetAlertError(isGenerateContactUsEmailMutationError);

  useEffect(() => {
    if (isGenerateContactUsEmailMutationSuccess) {
      setAlert({
        message: "Email was sent to policyholder's email address",
        severity: 'success',
      });

      navigateToOverview();
    }
  }, [isGenerateContactUsEmailMutationSuccess]);

  return (
    <>
      <TextField
        sx={{ ...commonSx, ...maxWidthSx, mx: 6, mt: 6 }}
        label="Email address"
        disabled
        value={policyHolderEmail}
      />
      <Stack direction="column" flex={1} justifyContent="flex-end">
        <StyledButtonsFooter sticky>
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={isGenerateContactUsEmailMutationPending}
            onClick={handleGenerateContactUsEmail}
          >
            Generate contact us email
          </LoadingButton>
        </StyledButtonsFooter>
      </Stack>
    </>
  );
}
