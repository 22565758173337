import { Alert } from '@mui/material';
import {
  getIsPolicyCancelled,
  getIsPolicyWithUpcomingMta,
} from '@/helpers/data/policyHelpers';
import { MotorPolicy } from '@/api/motorpolicy';

type OverviewPendingChangeAlertProps = {
  policy: MotorPolicy;
};

export function OverviewPendingChangeAlert({ policy }: OverviewPendingChangeAlertProps) {
  const isPolicyWithUpcomingMta = getIsPolicyWithUpcomingMta(policy);
  const isPolicyCancelled = getIsPolicyCancelled(policy);

  if (!isPolicyWithUpcomingMta && !isPolicyCancelled) return null;

  const cancelledPolicyMessage = 'This policy is scheduled to be cancelled.';
  const upcomingMtaMessage = `This policy has upcoming MTA's.`;

  return (
    <Alert severity="error">
      {isPolicyWithUpcomingMta && upcomingMtaMessage}
      {isPolicyCancelled && cancelledPolicyMessage}
    </Alert>
  );
}
